import { Pipe, PipeTransform } from '@angular/core';
import { KurzDifferentlyStyledValueObject } from '../../kurz-differently-styled-value.types';
import { attachObjectToTextPart } from '../functions/attach-object-to-text-part.function';
import { differentlyStyledFirstNumber } from '../functions/nth-number.function';


@Pipe({
  name: 'differentlyStyledTextPart'
})
export class DifferentlyStyledTextPartPipe implements PipeTransform {

  transform(value: string | KurzDifferentlyStyledValueObject[], textPart: string, mainRef: KurzDifferentlyStyledValueObject, howOften = 1, avoidObjectsWithActions?: boolean): KurzDifferentlyStyledValueObject[] {
    return attachObjectToTextPart(value, textPart, mainRef, howOften, avoidObjectsWithActions);
  }

}
