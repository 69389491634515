import { KurzDifferentlyStyledValueObject } from '../../kurz-differently-styled-value.types';


export function attachObjectToTextPart(value: string | KurzDifferentlyStyledValueObject[], textPart: string, mainRef?: KurzDifferentlyStyledValueObject, howOften: number = 1, avoidObjectsWithActions = false) {

  if (typeof value === 'string') {
    value = [ [value, 1, {}, null] ] as KurzDifferentlyStyledValueObject[];
  }

  if (howOften < 0) {
    howOften = Infinity;
  }

  let i: number;
  let obj: KurzDifferentlyStyledValueObject;

  for (i = 0; i < value.length && howOften; i++) {
    obj = value[i];

    if (avoidObjectsWithActions && obj?.[3]?.action) {
      continue; // ingnores the rest of the loop and continue with the next one
    }

    const index = obj[0].indexOf(textPart);
    if (index >= 0) {
      howOften--;
      let before: string;
      let main: string;
      let after: string;

      before = obj[0].slice(0, index);
      main = obj[0].slice(index, index + textPart.length);
      after = obj[0].slice(index + textPart.length);

      const replacements: KurzDifferentlyStyledValueObject[] = [];

      if (before) {
        replacements.push([before, obj[1], obj[2], obj[3]]);
      }

      if (main) {
        replacements.push([main, mainRef?.[1] || obj[1], mainRef?.[2] || obj[2], mainRef?.[3] || obj[3]]);
      }

      if (after) {
        replacements.push([after, obj[1], obj[2], obj[3]]);
      }

      // replace current obj with its replacement in its stead
      value.splice(i, 1, ...replacements);

      // because we add the replacements into value, the code will loop through them as well
      // the code is not allowed to loop through main because there it will find the textPart
      // again and it comes to a never ending recursision
      if (before) {
        i++;
      }

    }
  }

  return value;
}
