import { KurzDifferentlyStyledValueObject } from '../../kurz-differently-styled-value.types';
import { attachObjectToTextPart } from './attach-object-to-text-part.function';


export function splitIntoObjects(value: string | KurzDifferentlyStyledValueObject[], splitStr: string, deleteSplittingObjects: boolean = false, avoidObjectsWithActions = false) {

  value = attachObjectToTextPart(value, splitStr, null, -1, avoidObjectsWithActions);

  return deleteSplittingObjects ? value.filter(obj => obj[0] !== splitStr) : value;
}
