import { Pipe, PipeTransform } from '@angular/core';
import { KurzDifferentlyStyledValueObject } from '../../kurz-differently-styled-value.types';
import { splitIntoObjects } from '../functions/split.function';


@Pipe({
  name: 'differentlyStyledTextSplit'
})
export class DifferentlyStyledTextSplitPipe implements PipeTransform {

  transform(value: string | KurzDifferentlyStyledValueObject[], splitStr: string, deleteSplittingObjects?: boolean, avoidObjectsWithActions?: boolean): KurzDifferentlyStyledValueObject[] {
    return splitIntoObjects(value, splitStr, deleteSplittingObjects, avoidObjectsWithActions);
  }

}
